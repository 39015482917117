import * as React from "react";
import { Link } from 'gatsby'

const PostItem = ({ title, description, date, slug}) => {
    return(
        <Link className="post-item-link" to={slug}>
            <div className="post-item-container">
                <h2 className="post-title-text">{title}</h2>
                <p className="post-date-text">{date}</p>
                <p className="post-description-text">{description}</p>
            </div>
        </Link>
    );
}

export default PostItem;