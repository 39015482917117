import * as React from "react";
import PostItem from './PostItem';

const blogList = ({ posts }) => {
    
    return(
        <div className="post-list-container">
            <div className="left-margin-container"></div>
            <div className="post-item-list-container">
                {posts.map((post) => {
                    return (
                        <PostItem
                            key={post.title}
                            title={post.frontmatter.title}
                            date={post.frontmatter.date}
                            description={post.frontmatter.description}
                            slug={post.frontmatter.slug}
                        />
                    )
                })}
            </div>
            <div className="right-margin-container"></div>
        </div>
    );
}

export default blogList;