import * as React from "react";
import { graphql } from "gatsby";
import Layout from '../components/Layout';
import BlogList from '../components/BlogList';

const blog = ({ data }) => {
    const post_list = data.allMarkdownRemark.nodes;
    return(
        <Layout>
          <div className="blog-list-container">
            <BlogList posts={post_list}/>
          </div>
        </Layout>
    );
}

export const query = graphql`
  {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          date
          description
          slug
        }
      }
    }
  }
`

export default blog;